import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

// Constants 
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

// Setup
const canvas = document.querySelector('canvas.webgl')
const scene = new THREE.Scene()
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)

// Debug
// const gui = new GUI({title: 'gui'})
// const axes = new THREE.AxesHelper()
// scene.add(axes)

// Fullscreen on Double-Click
window.addEventListener('dblclick', () => {
    if (!document.fullscreenElement) {
        canvas.requestFullscreen()
    } else {
        document.exitFullscreen()
    }
})

// Textures
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('./textures/matcaps/1.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace

// Fonts
const fontLoader = new FontLoader()

fontLoader.load(
    './fonts/helvetiker_regular.typeface.json', 
    (font) => { 
        const textGeometry = new TextGeometry(
            'Rashi Kejriwal', 
            {
                font: font,
                size: 0.5,
                height: 0.5,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        )

        textGeometry.center()
        textGeometry.computeBoundingBox()
    
        const material = new THREE.MeshMatcapMaterial( { matcap: matcapTexture })
        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)

        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
        for (let i = 0; i < 10000; i++) {       
            const donut = new THREE.Mesh(donutGeometry, material)     
            donut.position.x = (Math.random() - 0.5) * 100
            donut.position.y = (Math.random() - 0.5) * 100
            donut.position.z = (Math.random() - 0.5) * 100
            
            donut.rotation.x = (Math.random() - 0.5) * Math.PI * 2
            donut.rotation.y = (Math.random() - 0.5) * Math.PI * 2
            
            const scale = Math.random()
            donut.scale.set(scale, scale, scale)
            scene.add(donut)
        }
    
        // Camera
        camera.position.set(6, 1, 8)
        camera.lookAt(text.position)
        scene.add(camera)
    }
)

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Controls
const controls = new OrbitControls(camera, canvas)
controls.maxDistance = 40
controls.enableDamping = true

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Animate
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()